var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid grid-cols-12 gap-2" },
    [
      _c(
        "vx-card",
        { staticClass: "col-span-12" },
        [
          _c("user-client-exceptions-filters", {
            on: { clearFilters: _vm.clear, fetch: _vm.reloadData },
            model: {
              value: _vm.filters,
              callback: function ($$v) {
                _vm.filters = $$v
              },
              expression: "filters",
            },
          }),
        ],
        1
      ),
      _vm.filters.user
        ? _c("user-client-exceptions-grid", {
            ref: "details-grid",
            staticClass: "col-span-12",
            attrs: { id: _vm.userId },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }