var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fieldset", { staticClass: "p-2 pt-0 border border-grey" }, [
    _c("legend", { staticClass: "px-1 font-bol" }, [
      _vm._v(" " + _vm._s(_vm.$t("filtros")) + " "),
    ]),
    _c(
      "div",
      { staticClass: "flex items-center p-2 flex-wrap gap-4" },
      [
        _c("select-suggestion", {
          ref: "select-user",
          staticClass: "flex-grow",
          attrs: {
            column: "name,email",
            model: "User",
            color: "dark",
            label: _vm.$t("usuario"),
            appendClearOption: true,
            placeholderText: "Digite o nome ou email do usuário",
            itemTextFormat: (model) =>
              `${model.name} <br/><div class=\'text-sm\'>${model.email}<div>`,
          },
          model: {
            value: _vm.values.user,
            callback: function ($$v) {
              _vm.$set(_vm.values, "user", $$v)
            },
            expression: "values.user",
          },
        }),
        _vm.showButtons
          ? _c(
              "div",
              { staticClass: "flex gap-1 ml-auto" },
              [
                _c(
                  "vs-checkbox",
                  {
                    staticClass: "text-sm smaller-checkbox",
                    model: {
                      value: _vm.values.autoFilter,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "autoFilter", $$v)
                      },
                      expression: "values.autoFilter",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("automatico")))]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "py-auto px-4",
                    attrs: { disabled: !_vm.values.user },
                    on: { click: _vm.emitFetch },
                  },
                  [_vm._v(_vm._s(_vm.$t("filtrar")))]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "py-auto px-4",
                    attrs: { type: "border", disabled: !_vm.isFiltering },
                    on: { click: _vm.clearFilters },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.clear")))]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }